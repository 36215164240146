import config from "../config";
import Parse from "parse"
const WSS_SERVER_URL = config.slaveServerUrl.replace("https","wss");
console.log('WSS_SERVER_URL :>> ', WSS_SERVER_URL);

const PARSE_APP_ID = config.appId;
const PARSE_JAVASCRIPT_KEY = config.javascriptKey;

const client = new Parse.LiveQueryClient({
    applicationId: PARSE_APP_ID,
    serverURL: WSS_SERVER_URL, // Example: 'wss://livequerytutorial.back4app.io'
    javascriptKey: PARSE_JAVASCRIPT_KEY
});
client.open();

export const listenSlaveTransaction = async (className) => {
    let query = new Parse.Query(className);
    let subscription = client.subscribe(query);
    return subscription;
};
