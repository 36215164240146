const options = [
    {
        key: "",
        label: "sidebar.home",
        leftIcon: "ion-home",
    },
    {
        key: "deposit",
        label: "sidebar.deposit",
        leftIcon: "ion-ios-redo",
    },
    {
        key: "payout",
        label: "sidebar.payout",
        leftIcon: "ion-ios-undo",
    },
    {
        key: "settlement",
        label: "sidebar.settlement",
        leftIcon: "ion-archive",
    },
    {
        key: "topup",
        label: "sidebar.topup",
        leftIcon: "ion-battery-charging",
    },
    {
        key: "bank",
        label: "sidebar.bank",
        leftIcon: "ion-cash",
    },
    {
        key: "admin",
        label: "sidebar.admin",
        leftIcon: "ion-ios-contact-outline",
    },
    {
        key: "role",
        label: "sidebar.role",
        leftIcon: "ion-android-hand",
    },
    {
        key: "activity",
        label: "sidebar.activity",
        leftIcon: "ion-android-walk",
    },
    {
        key: "serverLog",
        label: "sidebar.serverLog",
        leftIcon: "ion-monitor",
    },
    {
        key: "setting",
        label: "sidebar.setting",
        leftIcon: "ion-android-settings",
    },
];
export default options;
