import { language } from "../../settings";

import englishLang from "../../image/flag/uk.svg";
import chineseLang from "../../image/flag/china.svg";
import thaiLang from "../../image/flag/thailand.svg";


const config = {
  defaultLanguage: language,
  options: [
    {
      languageId: "english",
      locale: "en",
      text: "English",
      icon: englishLang
    },
    {
      languageId: "thai",
      locale: "th",
      text: "Thai",
      icon: thaiLang
    },
    {
      languageId: "chinese",
      locale: "zh",
      text: "Chinese",
      icon: chineseLang
    },
  ]
};

export function getCurrentLanguage(lang) {
  let selecetedLanguage = config.options[0];
  config.options.forEach(language => {
    if (language.languageId === lang) {
      selecetedLanguage = language;
    }
  });
  return selecetedLanguage;
}
export default config;
