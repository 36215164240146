import Enlang from "./entries/en-US";
import Zhlang from "./entries/zh-Hans-CN";
import Thlang from "./entries/th_TH";
import { addLocaleData } from "react-intl";

const AppLocale = {
    en: Enlang,
    zh: Zhlang,
    th: Thlang,
};
addLocaleData(AppLocale.en.data);
addLocaleData(AppLocale.zh.data);
addLocaleData(AppLocale.th.data);

export default AppLocale;
