import React, { Component } from "react";
import { connect } from "react-redux";
import { Layout, Statistic, Row, Col } from "antd";
import appActions from "../../redux/app/actions";
import TopbarLogout from "./topbarLogout";
import TopbarWrapper from "./topbar.style";
import themes from "../../settings/themes";
import { themeConfig } from "../../settings";
import { getCurrentUser, listenTransaction } from "../../helpers/parseHelper";
import moment from "moment";
import "moment/locale/th";
moment.locale("en");

import { ClockCircleOutlined } from "@ant-design/icons";
const { Header } = Layout;
const { toggleCollapsed } = appActions;
const customizedTheme = themes[themeConfig.theme];

class Topbar extends Component {
    state = {
        user: null,
        balance: 0,
    };
    async componentDidMount() {
        this.loadData();
    }
    loadData = async () => {
        const user = await getCurrentUser();
        const balance = user.get("balance");
        this.setState({ user, balance });
    };

    render() {
        const { toggleCollapsed } = this.props;
        const collapsed = this.props.collapsed && !this.props.openDrawer;
        const styling = {
            background: customizedTheme.backgroundColor,
            position: "fixed",
            width: "100%",
            height: 70,
        };
        const { user, balance } = this.state;
        return (
            <TopbarWrapper>
                <Header style={styling} className={collapsed ? "isomorphicTopbar collapsed" : "isomorphicTopbar"}>
                    <div className="isoLeft">
                        <button
                            className={collapsed ? "triggerBtn menuCollapsed" : "triggerBtn menuOpen"}
                            style={{ color: customizedTheme.textColor }}
                            onClick={toggleCollapsed}
                        />
                        <div style={{ marginLeft: 12, marginRight: 16, fontSize: 10 }}>{`[ ${(user && user.get("username")) || ""} ]`}</div>
                    </div>

                    <ul className="isoRight">
                        <li onClick={() => this.setState({ selectedItem: "user" })} className="isoUser">
                            <TopbarLogout />
                        </li>
                    </ul>
                </Header>
            </TopbarWrapper>
        );
    }
}

export default connect(
    (state) => ({
        ...state.App,
    }),
    { toggleCollapsed }
)(Topbar);
