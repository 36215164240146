const env = {};
const PROD = true;
const URL = PROD ? DOMAIN : "http://localhost";
const PORT = APP_PORT || 1337;
env.url = URL;
env.port = PORT;
env.parseServerUrl = `${URL}:${PORT}/parse`;
env.slaveServerUrl = `${URL}:${SLAVE_PORT || 1448}/parse`;
env.apiUrl = `${URL}:${PORT}/api/v1`;
env.appId = P_APP_ID;
env.masterKey = P_MASTER_KEY;
env.javascriptKey = P_JS_KEY;
env.appName = APP_NAME;
env.version = VERSION;
export default env;
