import antdEn from "antd/lib/locale-provider/th_TH";
import appLocaleData from "react-intl/locale-data/th";
import enMessages from "../locales/th_TH.json";
// import { getKeys, getValues } from '../conversion';
// getValues(enMessages);

const EnLang = {
    messages: {
        ...enMessages,
    },
    antd: antdEn,
    locale: "th",
    data: appLocaleData,
};
export default EnLang;
