import React, { Component } from "react";
import { Route } from "react-router-dom";
import asyncComponent from "../../helpers/AsyncFunc";
import { getCurrentUserPermissions } from "../../helpers/parseHelper";

const routes = [
    {
        path: "",
        component: asyncComponent(() => import("../Home")),
    },
    {
        path: "serverLog",
        component: asyncComponent(() => import("../ServerLog")),
    },
    {
        path: "deposit",
        component: asyncComponent(() => import("../Deposit")),
    },
    {
        path: "payout",
        component: asyncComponent(() => import("../Payout")),
    },
    {
        path: "settlement",
        component: asyncComponent(() => import("../Settlement")),
    },
    {
        path: "topup",
        component: asyncComponent(() => import("../Topup")),
    },
    {
        path: "admin",
        component: asyncComponent(() => import("../AdminUser")),
    },
    {
        path: "role",
        component: asyncComponent(() => import("../Role")),
    },
    {
        path: "setting",
        component: asyncComponent(() => import("../GlobalConfig")),
    },
    {
        path: "activity",
        component: asyncComponent(() => import("../Activity")),
    },
    {
        path: "bank",
        component: asyncComponent(() => import("../Bank")),
    },
];

class AppRouter extends Component {
    state = {
        filteredRoutes: null,
    };
    componentDidMount = async () => {
        const permissionItems = await getCurrentUserPermissions();
        console.log("permissionItems on routes", permissionItems);
        const mappedPermissions = Object.keys(permissionItems || {})?.filter((item) => permissionItems[item]?.includes("VIEW"));
        console.log("mappedPermissions route", mappedPermissions);
        mappedPermissions.push("");
        const filteredRoutes = routes.filter((item) => mappedPermissions.includes(item.path.toUpperCase()));
        console.log("filteredRoutes", filteredRoutes);
        this.setState({ filteredRoutes });
    };
    render() {
        const { url, style } = this.props;
        const { filteredRoutes } = this.state;
        return (
            <div style={style}>
                {filteredRoutes
                    ? filteredRoutes.map((singleRoute) => {
                          const { path, exact, ...otherProps } = singleRoute;
                          return <Route exact={exact === false ? false : true} key={singleRoute.path} path={`${url}/${singleRoute.path}`} {...otherProps} />;
                      })
                    : null}
            </div>
        );
    }
}

export default AppRouter;
