export default {
  apiUrl: 'http://yoursite.com/api/',
};
import config from '../config'

const siteConfig = {
  siteName: config.appName,
  siteIcon: 'ion-flash',
  footerText: config.appName + ' ©2023 version ' + config.version,
};
const themeConfig = {
  topbar: 'themedefault',
  sidebar: 'themedefault',
  layout: 'themedefault',
  theme: 'themedefault',
};
const language = LANGUAGE;

const jwtConfig = {
  fetchUrl: '/api/',
  secretKey: 'secretKey',
};

export { siteConfig, language, themeConfig, jwtConfig };
